@import "../../assets/styles/local-variables";

.btn {
  display: inline-block;
  min-width: 300px;
  padding: 20px 48px;
  text-align: center;
  font-weight: 700;
  border: none;
  border-radius: 8px;
  background-color: #c5f1de;
  color: black;
  font-size: 1rem;
  // text-transform: capitalize;
  font-weight: 300;
  margin-bottom: 1rem;
  transition: $transition-function;

  &:focus {
    outline: none;
  }

  &--sm {
    padding: 10px 24px;
    font-size: 1rem;
  }

  &--primary {
    background-color: #c5f1de;
    color: black;

    &:hover {
      background-color: #8de3be;
    }

    &:focus {
      box-shadow: 0 0 0 5px rgba($blue-primary, 0.2);
    }

    &:active {
      background-color: $green-secondary;
    }
  }

  &--secondary {
    background: white;
    border: 1px solid $green-secondary;

    &:hover {
      background-color: #c5f1de;
    }
  }

  &--outline-primary {
    background-color: $white;
    border: 1px solid $blue-primary;
    color: $blue-primary;

    &:hover {
      background-color: $blue-primary;
      color: $white;
    }

    &:focus {
      box-shadow: 0 0 0 5px rgba($blue-primary, 0.2);
    }

    &:active {
      background-color: darken($blue-primary, 5%);
    }
  }

  &--danger {
    color: $white;
    background-color: tomato;

    &:hover {
      color: $white;
      background-color: darken(tomato, 5%);
    }

    &:focus {
      box-shadow: 0 0 0 5px rgba(tomato, 0.2);
    }

    &:active {
      background-color: darken(tomato, 10%);
    }
  }

  &--outline-danger {
    background-color: $white;
    border: 1px solid tomato;
    color: tomato;

    &:hover {
      background-color: tomato;
      color: $white;
    }

    &:focus {
      box-shadow: 0 0 0 5px rgba(tomato, 0.2);
    }

    &:active {
      background-color: darken(tomato, 5%);
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #f6f6f6;
    color: $black;
  }
}

.form-group {
  margin-bottom: 1rem;

  label {
    display: block;
  }
}

.form-control {
  width: 100%;
  background-color: transparent;
  border: 1px solid rgba($blue-primary, 0.2);
  transition: $transition-function;
  padding: 16px;
  font-size: 1.15rem;
  border-radius: 5px;

  &::placeholder {
    font-size: 1rem;
  }

  &:focus {
    outline: none;
    border-color: $blue-primary;
  }

  &.error {
    border-color: tomato;
  }

  &:disabled {
    background-color: #eeeeee;
    cursor: not-allowed;
  }

  @media (min-width: $breakpoint-lg) {
    width: 100%;
  }
}

.c-checkbox {
  display: flex;
  align-items: center;

  &__native {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);

    &:checked {
      & + .c-checkbox__custom {
        background: url(../../assets/img/checkbox-mark.svg) no-repeat 50% #fff;
      }
    }
  }

  &__custom {
    position: relative;
    flex-shrink: 0;
    width: 33px;
    height: 33px;
    background-color: $white;
    border: 1px solid rgba($blue-primary, 0.2);
    border-radius: 7px;
  }

  &__label {
    margin-left: 0.5rem;
  }
}
