.overview {
  background-color: white;
  height: 100%;
  padding: 4rem 8rem;
  display: grid;
  justify-content: center;
  align-content: flex-start;
  gap: 2rem;

  &__title {
    font-size: 2rem;
    text-align: center;
  }

  &__subtitle {
    font-size: 1.6rem;
    font-weight: 500;
    text-align: center;
  }

  &__form {
    width: 30rem;
    display: grid;
    gap: 1rem;
  }

  &__table {
    width: 100%;

    td,
    th {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #dcdcdc;
    }

    thead {
      th {
        vertical-align: bottom;
      }
    }
  }
}
