.logged-out {
  background-color: white;
  height: 100%;
  display: grid;
  align-content: center;
  padding: 4rem;

  &__title {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;
  }
}
