.privacy-policy {
  background-color: white;
  height: 100%;
  padding: 4rem 8rem;
  display: grid;
  justify-items: center;
  align-content: flex-start;
  gap: 4rem;

  &__headings {
    display: grid;
    text-align: center;
  }

  &__title {
    font-size: 2rem;
    font-weight: 300;
  }

  &__subtitle {
    font-size: 1.1rem;
    font-weight: 500;
  }

  &__content {
    display: grid;
    gap: 1rem;
  }

  &__group {
    max-width: 70rem;
    width: 60vw;
    background-color: rgba(98, 98, 98, 0.05);
    border-radius: 2rem;
    transition: all 0.2s;

    &[open] {
      background-color: rgba(208, 235, 225, 0.6);

      .privacy-policy__group--summary::after {
        transform: translateY(-50%) rotateZ(90deg);
      }
    }

    &--summary {
      padding: 1.5rem 4rem;
      font-size: 1.2rem;
      display: grid;
      gap: 0.5rem;
      font-weight: 500;
      text-transform: uppercase;
      color: rgb(31, 31, 31);
      transition: all 0.1s;
      position: relative;

      &::after {
        content: "\203A";
        font-size: 2rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 4rem;
        transition: all 0.2s;
      }

      &:focus {
        outline: none;
      }
    }

    &--content {
      margin-top: -1rem;
      padding: 1.5rem 4rem;

      ul {
        list-style: circle;
        font-size: 1.1rem;
        display: grid;
        gap: 0.5rem;
      }

      h3 {
        font-weight: 500;
      }

      a {
        text-decoration: underline;
        color: blue;
      }
    }
  }
}
