@import "../../../assets/styles/local-variables";

.header {
  padding: 20px 0px; // 65
  font-size: 1.15rem;
  background-color: white;
  border-bottom: 1px solid #b7f6db;

  // background-color: transparent;
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100vw;
  // z-index: 999;

  &-container-fluid {
    padding: 0.4rem 8rem;
    margin: 0;
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    padding-right: 45px;
  }

  &__logo {
    text-transform: none;
    font-size: 1rem;

    img {
      width: 30px;
      height: 30px;
    }
  }

  &__lang {
    margin-left: auto;
    margin-right: 0px;
    // background-color: green;
  }

  @media (min-width: $breakpoint-lg) {
    &__lang {
      margin-left: 0px;
    }
  }
}

.nav {
  ul {
    display: flex;
    align-items: center;
    margin: 0 -45px 0;
    padding: 0;
    list-style: none;
  }

  li {
    padding: 0 45px;
  }

  @media (min-width: $breakpoint-lg) {
    margin-left: auto;
  }
}

.nav-link {
  color: #7c8d9d;
  font-size: 1rem;
  font-weight: 400;

  &--new {
    position: relative;
  }

  &__new {
    position: absolute;
    top: -1rem;
    right: -1rem;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: capitalize;
    color: green;
  }

  &:hover {
    text-decoration: underline;
    color: $blue-primary;
  }

  &:focus {
    outline: none;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: rgba($blue-primary, 0.65);
  }

  &.active {
    color: black;
    font-weight: 500;

    svg {
      fill: $blue-primary;
    }
  }
}
