.account-deleted {
  background-color: white;
  padding: 4rem;
  display: grid;
  align-content: center;
  height: 100%;

  &__title {
    text-align: center;
    font-weight: 500;
    font-size: 1.8rem;
  }
}
