@import "../../../assets/styles/local-variables";

html,
body,
#root {
  height: 100%;
}

html {
  font-size: 12px;

  @media (min-width: $breakpoint-lg) {
    font-size: 14px;
  }
}

.page-wrapper {
  background-position: center;
  background-size: cover;
  // background-image: url(../../../assets/img/app-bg.png);
  background-image: linear-gradient(
    to right bottom,
    rgba(183, 246, 219, 1),
    rgba(112, 212, 235, 1),
    rgba(88, 218, 200, 1),
    rgba(21, 170, 204, 1)
  );
  height: 100vh;
  display: grid;
  overflow: scroll;
  // justify-content: stretch;
  grid-template-rows: min-content 1fr;
}

.container-fluid {
  max-width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  // background-color: #01fd92;

  // @media (min-width: $breakpoint-xl) {
  //   max-width: 1760px;
  //   padding: 0 64px;
  // }
}

.layout-children {
  // padding-top: 80.5px;
  // background-color: purple;
  padding-left: 0px; // 65
  padding-right: 0px; // 65
  width: 100%;
  height: 100%;
}

.inner-container {
  width: 100%;
  max-width: 1540px;
  margin: 0 auto;
}

.main {
  @media (min-width: $breakpoint-lg) {
    // padding: 84px 0 24px;
    padding: 0;
    display: flex;
    justify-content: stretch;
    align-items: stretch;

    // background-color: orange;

    .container-fluid {
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - 332px);
    }
  }
}

.auth-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1380px;
  margin: 0 auto;
  padding: 2rem 0;
  background-color: rgba($white, 0.4);
  box-shadow: 0 10px 40px #0000001a;
  border-radius: 8px;

  &__subtitle {
    margin-bottom: 1rem;
    font-size: 1.7rem;
    color: $blue-primary;
  }

  &__info {
    margin-top: 1rem;
    font-size: 1.3rem;

    a {
      margin: 0 0.5rem;
      text-decoration: underline;
      color: $blue-primary;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__go-home-block {
    margin-top: 2rem;

    a {
      display: flex;
      align-items: center;
      color: $blue-primary;

      svg {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        margin-right: 8px;
        fill: $blue-primary;
      }

      span {
        text-decoration: underline;
      }
    }
  }
}

.auth-form {
  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    font-size: 1.15rem;
    color: $blue-primary;

    & > .form-group {
      margin-bottom: 0;
    }
  }

  &__forgot {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }

  .btn {
    width: 100%;
  }
}

.title {
  margin-bottom: 1.7rem;
  text-transform: uppercase;
  font-size: 1.43rem;
  font-weight: 700;
  color: $blue-primary;
}

.text-content {
  font-size: 1.43rem;
  line-height: 1.2;

  p {
    margin-bottom: 0.85rem;
  }

  &--sm {
    font-size: 1.15rem;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  box-shadow: 0 10px 40px #0000001a;

  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.profile-photo-wrapper {
  max-width: 420px;
}

.profile-photo {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 50%;
  background-color: $blue-primary;

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.7rem;
    font-weight: 700;
    color: $white;
  }
}

.profile-form {
  .form-group {
    max-width: 400px;
  }
}

.calibration-container {
  margin-bottom: 3.2rem;
}

.calibration-box {
  position: relative;
  margin-bottom: 24px;
  padding-bottom: 65.6%;
  background: rgba($white, 0.7);
  box-shadow: 0 10px 40px #0000001a;
  border-radius: 15px;
  border: 5px solid transparent;

  &__hint {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 700;
  }

  &__title {
    position: absolute;
    bottom: 4px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 700;
    color: $blue-primary;
    text-transform: uppercase;
  }

  &__check {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      max-width: 120px;
      max-height: 90px;
    }
  }

  &--current {
    border-color: #1d70b7;
  }

  &--done {
    background-color: #01fd92;

    .calibration-box__title {
      color: $white;
    }
  }
}

.example-chart-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 1rem);

  .mb-auto {
    margin-bottom: auto;
  }
}

.example-chart {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  height: 180px;
  margin-top: 2rem;

  &__item {
    width: 26px;
    background: linear-gradient(180deg, #01fd92 0%, #1d70b7 100%);

    &:not(:last-child) {
      margin-right: 32px;
    }
  }
}

.text-content {
  h1,
  h2 {
    font-weight: 700;
  }

  h1 {
    text-align: center;
    font-size: 2rem;
  }

  h2 {
    font-size: 1.7rem;
  }

  a {
    text-decoration: underline;
  }
}
