.settings {
  background-color: white;
  height: 100%;
  padding: 3rem 8rem;

  &__headings {
    display: grid;
    gap: 0.2rem;
  }

  &__title {
    color: black;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 0;

    span {
      color: #d2d2d2;
    }
  }

  &__subtitle {
    color: #00203f;
    font-size: 0.95rem;
  }

  &__main {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 6rem;
    align-items: flex-start;
    margin-top: 1.2rem;

    &--navigation {
      display: grid;
      gap: 0.4rem;

      hr {
        background-color: red;
        border: 0.5px solid #c1d2d8;
      }
    }

    &--nav-link {
      color: #00203f;

      &.delete-account {
        color: #eb5757;
      }

      &.active {
        font-weight: 500;
      }
    }

    &--controls {
      display: grid;
      align-items: flex-start;
      max-width: 65rem;
    }
  }

  &__subscription {
    display: grid;
    grid-template-columns: max-content 1fr;
    width: 100%;

    &--plans {
      display: grid;
      gap: 0.7rem;
    }

    &--status {
      justify-self: flex-end;

      p {
        display: block;
        width: auto;
        font-size: 1.2rem;

        span {
          color: #8de3be;
          font-weight: 500;
        }
      }
    }
  }

  &__btn {
    &--container {
      display: flex;
      gap: 0.8rem;
      margin-top: 2rem;
    }
    // !important to overwrite library code
    color: black !important;
    font-size: 0.8rem !important;
    font-weight: 300 !important;
    border-radius: 8px !important;
    // margin-bottom: 1rem;
    padding: 1rem 3.8rem;
    min-width: auto;

    &--submit {
      background-color: #c5f1de !important;
      &:hover {
        background-color: #8de3be !important;
      }
    }

    &--cancel {
      background-color: #e6edf0 !important;
      &:hover {
        background-color: #cbd1d4 !important;
      }
    }

    &--subscription {
      background-color: white;
      border: 1px solid #59a785;
      &:hover {
        background-color: #8de3be;
      }
    }
  }

  &__errorMsg {
    color: #eb5757 !important;
    font-size: 15px;

    &--no-alert {
      color: black !important;
    }
  }
}

form label {
  font-weight: 500;
}
