@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src:
            url('../fonts/montserrat-400.woff2') format('woff2'),
            url('../fonts/montserrat-400.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src:
            url('../fonts/montserrat-500.woff2') format('woff2'),
            url('../fonts/montserrat-500.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src:
            url('../fonts/montserrat-700.woff2') format('woff2'),
            url('../fonts/montserrat-700.woff') format('woff');
    font-display: swap;
}
