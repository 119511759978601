@import "../../assets/styles/local-variables";

.confirm-email {
  background-color: white;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 4rem;

  &__content {
    display: grid;
    gap: 3rem;
    justify-items: center;
  }

  &__error {
    font-size: 1.4rem;
    font-weight: 500;
  }
}
