.fakedoor__modal {
  &--text-box {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-self: center;
  }

  &--icon {
    right: 1.8rem;
    top: -1rem;
    display: block;
    width: 34px;
    align-self: flex-end;
    transform: translateX(-18.8px);
    margin-bottom: 10px;
  }

  &--heading {
    font-size: 1.6rem;
    line-height: 1.1;
  }

  &--subheading {
    font-size: 1rem;
    margin-bottom: 0;
  }
}
