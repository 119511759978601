.confirm-email-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0;

  &--content {
    background-color: white;
    padding: 2rem 4.5rem;
    position: relative;
    max-width: 50rem;
    width: 90vw;
    display: grid;
    gap: 0.7rem;
    // border: 2px solid black;
    border-radius: 1.2rem;
  }

  &--heading {
    margin-bottom: 0;
    font-size: 1.6rem;
  }

  &--subtitle {
    color: #3f3f3f;
    font-size: 1.1rem;
  }

  &--action {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 2rem;

    svg {
      height: 5rem;
      width: 5rem;

      path {
        fill: #64ceb5;
      }
    }
  }

  &--action-texts {
    display: grid;
    justify-items: center;
    gap: 2rem;
  }

  &--action-text {
    color: #3f3f3f;
    font-size: 1.1rem;
  }
}
