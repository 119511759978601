.auth-page {
  &--container {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    max-width: 100vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: stretch;
    justify-content: stretch;
    padding: 0;
    z-index: 999; // To hide the header and footer

    @media only screen and (max-width: 62.5em) {
      // 1000px
      grid-template-columns: 1fr;
    }
  }

  &--content {
    &__container {
      padding: 3rem 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: scroll;
    }

    display: grid;
    flex: 1;
    align-content: start;
    padding: 0 2rem;
    justify-content: center;
    grid-template-columns: 28rem;
    gap: 1rem;
  }

  &--footer {
    padding: 2rem 7rem;
  }

  &--footer-text,
  &--footer-link {
    color: #c1d2d8;
    text-align: center;
    font-size: 11px;
    text-align: center;
  }

  &--footer-link {
    text-decoration: underline;
    font-size: 12px;
    transition: all 0.2s;
  }

  // Right side - Image section
  &--image-side {
    background-color: #05e8a4;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5rem;
    padding-top: 3rem;
    border: 1px solid #fff;
    background-repeat: no-repeat;
    background-size: cover;

    @media only screen and (max-width: 62.5em) {
      display: none;
    }
  }

  &--language-changer {
    align-self: flex-end;
    backdrop-filter: blur(10px);
  }

  &--icon-world {
    cursor: pointer;
    width: 2.4rem;
    height: 2.4rem;
  }

  &--quote {
    background-color: rgba(255, 255, 255, 0.6);
    width: 80%;
    padding: 2.5rem 2.8rem;
    text-align: center;
    border-radius: 8px;

    &__text {
      font-size: 18px;
      font-style: italic;
      font-weight: 400;
    }

    &__author {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}

.fitsit-logo--container {
  margin-bottom: 2rem;
}
