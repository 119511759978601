.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.col-lg-3 {
  @media (min-width: $breakpoint-lg) {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.col-lg-4 {
  @media (min-width: $breakpoint-lg) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.col-lg-5 {
  @media (min-width: $breakpoint-lg) {
    flex: 0 0 41.6666667%;
    max-width: 41.6666667%;
  }
}

.col-lg-6 {
  @media (min-width: $breakpoint-lg) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col-lg-8 {
  @media (min-width: $breakpoint-lg) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

.offset-lg-1 {
  @media (min-width: $breakpoint-lg) {
    margin-left: 8.333333%;
  }
}
