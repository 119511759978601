.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  &__play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 81px;
    height: 72px;
    padding: 0;
    background-color: transparent;
    background-image: url("../../assets/img/icon-play.svg");
    background-repeat: no-repeat;
    background-size: 81px 72px;
    border: 0;
  }

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
}
