@import "../../assets/styles/local-variables";

.setup-block {
  margin-bottom: 2rem;

  &__title {
    margin-bottom: 0.25rem;
  }

  &__pill {
    width: 100%;
    height: 24px;
    background: $green-primary linear-gradient(270deg, #05e8a4 0%, #1d70b7 100%);
    border-radius: 24px;
  }
}

.practice {
  background-color: white;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  height: 90vh;
  height: 100%;

  &__warning-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1;
    gap: 20px;

    &--text {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
    }
  }

  &__text-container {
    padding: 4rem 13rem;
    justify-content: center;
    position: relative;
  }

  &__text-icon {
    position: absolute;
    top: 1rem;
    width: 4rem;
  }

  &__heading {
    font-size: 4rem;
    line-height: 1.1;
    font-weight: 500;

    span {
      color: #58dac8;
    }
  }

  &__text-side {
    background-color: white;
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
  }

  &__sub-heading {
    font-size: 1.1rem;
    width: 85%;
  }

  &__video-side {
    align-self: stretch;
    display: grid;
    grid-template-columns: 0.5fr;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    background-image: linear-gradient(
      to right bottom,
      #b7f6db,
      #70d4eb,
      #58dac8,
      #15aacc
    );

    grid-column: 1 / span 2;
    width: 50%;
    height: 100%;
    justify-self: end;
    grid-row: 1 / span 1;
    z-index: 2;
    transition: all 0.4s;

    &--fullscreen {
      width: 100%; // transitions into the full screen width
    }
  }

  &__video-container {
    display: grid;
    gap: 2rem;
  }

  &__video {
    background-color: #00203f;
    border-radius: 1rem;
    border: 2px solid white;
    padding: 2rem;
    // width: 50%;
    display: grid;
    height: 30rem;
    justify-items: center;
  }
}

.tracking-tracker__container {
  display: flex;
  align-items: stretch;
}

.tracking-progress {
  &__container {
    text-align: center;
    display: grid;
    justify-items: center;
    gap: 0.6rem;
  }

  &__heading {
    color: #00203f;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 0;
  }

  &__bar {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 1rem;
    border-radius: 5px;

    &::-webkit-progress-bar {
      background-color: #e6edf0;
      border-radius: 10px;
    }

    &::-moz-progress-bar {
      border-color: #e6edf0;
      border-radius: 10px;
    }

    &::-webkit-progress-value {
      background-color: #00203f;
      border-radius: 10px;
    }
  }

  &__info {
    color: #7c8d9d;
    font-size: 1.05rem;
    margin-bottom: 0;
  }

  &__total-completed {
    margin-top: 5px;
    display: flex;
    align-items: center;

    p {
      font-size: 1rem;
    }

    .btn {
      margin-bottom: 0;
      min-width: auto;
      width: 190px;
    }
  }
}
