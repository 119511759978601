.statistics {
  background-color: white;
  padding: 2rem 8rem;
  min-height: 100%;
  display: grid;
  gap: 1rem;

  &__headings {
    margin: 0;
  }

  &__heading {
    &--title {
      font-size: 2.5rem;
      margin-bottom: 0;
    }

    &--subtitle {
      color: #00203f;
      font-size: 1rem;
    }
  }

  &__no-session {
    align-self: flex-start;
    display: grid;
    justify-items: center;
    gap: 20px;

    &--text {
      font-size: 1.4rem;
      text-align: center;
    }
  }

  &__graph-container {
    display: grid;
    gap: 3rem;
  }

  &__dashboard {
    display: flex;
    flex-wrap: wrap;

    &--item {
      flex: 1 1 0;
      text-align: center;
      display: grid;
      justify-content: center;
    }

    &--item-title {
      font-size: 1.2rem;
      font-weight: 300;
      color: #00203f;
      font-weight: 500;
      margin: 0;
    }

    &--item-value {
      font-size: 1.8rem;
      color: rgb(69, 69, 69);
    }
  }

  &__control-btns {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
}
