$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

//$black-100: #323232;
//$black-15: rgba($black-100, .15);
//$black-35: rgba($black-100, .35);

$black: #000;
$white: #fff;

$blue-primary: #1d70b7;
$green-primary: #01FD92;
$green-secondary: #05E8A4;

$transition-function: all .3s ease-out;
