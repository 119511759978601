.subExpired-container {
  background-color: white;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
}

.subExpired {
  border: 2px solid #c5f1de;
  display: grid;
  gap: 15px;
  max-width: 500px;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  animation: fadeInSlideDown 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &__title {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
}

@keyframes fadeInSlideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
}
