.modal {
  &--container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
  }

  &--content {
    background-color: white;
    padding: 4.5rem 3rem;
    position: relative;
    width: 35rem;
    height: 27rem;
    border-radius: 1.2rem;
    animation: modalSlideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  &--icon-cancel {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }

  @keyframes modalSlideIn {
    0% {
      transform: translateY(10rem);
    }
  }
}
