.exercises {
  height: 100%;
  display: grid;
  gap: 3rem;
  align-content: center;
  justify-content: center;
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
  //   width: 50%;

  &__btns {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }

  &__btn {
    border: 2px solid transparent;
    position: relative;

    &--active {
      border-color: #fff;
      background-color: #8de3be;
      box-sizing: border-box;
      box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.2);

      &:disabled {
        background-color: #8de3be !important;
        color: #000 !important;
        cursor: text;

        & .exercises__btn__info {
          display: none;
        }
      }
    }

    &:disabled {
      background-color: rgba(255, 255, 255, 0.6);
      color: rgba(0, 0, 0, 0.6);

      &:hover .exercises__btn__info {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      }
    }

    &__info {
      position: absolute;
      padding: 0.2rem 0.5rem;
      border-radius: 5px;
      top: -3.4rem;
      left: 5%;
      font-size: 12px;
      width: 90%;
      background-color: #fff;
      color: #000;
      visibility: hidden;
      opacity: 0;
      transform: translateY(100%);
      transition: all 0.1s;
    }
  }

  &__video {
    width: 50vw;
    margin: auto;
    margin-bottom: 30px;
    position: relative;
    display: grid;
    height: 350px;
    justify-items: center;

    &__control {
      background-color: #00203f;
      border-radius: 1rem;
      border: 2px solid white;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.hidden {
        display: none;
      }
    }
  }

  &__progress {
    text-align: center;
  }
}
