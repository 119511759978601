@import "../../assets/styles/local-variables";

.modal {
  //display: none;

  &__overlay {
    // background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent linear-gradient(180deg, #01fd9200 0%, #01fd92 100%);
    //opacity: 0;
    //transition: $transition-function;
  }

  &__container {
    position: relative;
    background-color: $white;
    background-image: url("../../assets/img/practice-completed-image.png");
    background-size: contain;
    background-repeat: no-repeat;
    // background-size: 80%;
    // background-color: peru;
    padding: 100px 24px 80px;
    width: 50vw;
    max-height: 100vh;
    overflow-y: auto;
    box-shadow: 0 10px 40px #0000001a;
    border-radius: 20px;

    display: grid;
    justify-content: center;
    grid-template-rows: 1fr repeat(2, min-content);
  }

  &__content {
    text-align: center;
    display: grid;
    gap: 0.4rem;
    // background-color: skyblue;

    .survey-link {
      // text-decoration: underline;
      // font-size: 1.3rem;
    }
  }

  &__close {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    background-color: transparent;
    border: 0;
    color: $blue-primary;
    font-size: 40px;
    font-weight: 700;
  }

  &__title {
    // font-size: 2.85rem;
    // font-weight: 700;
    // color: $blue-primary;
    height: 15rem;
    display: grid;
    align-content: flex-start;
    gap: 0.1rem;

    h2 {
      color: #00203f;
      font-size: 3rem;
      font-weight: 500;
      margin-bottom: 0;
    }

    p {
      color: white;
      font-size: 1.3rem;
      width: 100%;
    }
  }

  &__subtitle {
    // max-width: 520px;
    margin-top: 0.5rem;
    p {
      font-size: 1rem;
      width: 100%;
    }
  }

  &__buttons {
    display: grid;
    justify-content: center;
  }

  &__btn {
    &--take-survey {
      // width: 10rem;
      display: block;
      padding: 1.2rem 5rem;
      text-decoration: none;
      // !important to overwrite library code
      background-color: #c5f1de !important;
      color: black !important;
      font-size: 0.8rem !important;
      // text-transform: capitalize !important;
      font-weight: 300 !important;
      border-radius: 8px !important;
      margin-bottom: 0.4rem;

      &:hover {
        background-color: #8de3be !important;
      }
    }

    &--skip {
      padding: 0;
      background-color: transparent;
      font-size: 1rem;
      font-weight: 300;
      text-transform: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &.is-open {
    display: block;

    .modal__overlay {
      opacity: 1;
    }
  }

  @media (min-width: 992px) {
    &__container {
      max-width: 800px;
    }

    &__content {
      .btn {
        //width: auto;
        //min-width: 500px;
      }
    }
  }
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.show {
  display: block;
}

.micromodal-slide.show .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide.show .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}
