.forgot-password {
  &--header {
    display: grid;
    justify-items: flex-start;
    margin-bottom: 0.5rem;
  }

  &--heading {
    margin-bottom: 0;
    font-size: 1.6rem;
  }

  &--action-text {
    color: #757575;
    font-size: 1rem;
  }

  &--form-group__label {
    font-size: 1rem;
    margin-bottom: 0px;
    margin-bottom: 0.5rem;
  }
}

.forgot-password-sent--text-box {
  position: relative;
}

.sent-header {
  display: grid;
  align-content: center;
  justify-items: center;
  height: 100%;

  .forgot-password {
    &--heading,
    &--action-text {
      text-align: center;
    }
  }
}

.green-icon {
  &--box {
    position: absolute;
    right: 1.8rem;
    top: -1rem;
  }

  &--image {
    display: block;
  }
}
