.how-to {
  margin: 0 0rem;
  padding: 0 9rem;
  background-color: white;
  height: 100%;
  display: grid;
  align-content: center;
  justify-items: center;
  gap: 5rem;

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-content: center;
    gap: 8rem;
    justify-self: stretch;
  }

  &__email-confirmed {
    font-size: 1.4rem;
    font-weight: 300;
    text-align: center;
  }

  &__video-side {
    //   height: 200px;
    position: relative;

    &--icon {
      position: absolute;
      top: -3rem;
      width: 4rem;
    }
  }

  &__text-side {
    background-color: white;
    display: grid;
    align-content: flex-start;
    gap: 0.2rem;
  }

  &__title {
    font-size: 3.5rem;
    line-height: 1.1;
    font-weight: 500;

    span {
      color: #58dac8;
    }
  }

  &__subtitle {
    font-size: 1.2rem;
    width: 85%;
    // background-color: cyan;
  }

  &__start-practice-btn {
    width: 50%;
  }
}
