@import "../../assets/styles/local-variables";

.lang-changer {
  position: relative;
  cursor: pointer;
  z-index: 99;
  font-size: 16px;
  color: black;
  text-transform: uppercase;

  &__current {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      width: 30px;
      height: 20px;
      opacity: 0.75;
    }
  }

  &__dropdown {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    padding: 4px;
    background-color: rgba($white, 0.9);
    box-shadow: 0 2px 4px #ccc;
    border-radius: 4px;
    transition: $transition-function;
  }

  &__item {
    &:hover {
      text-decoration: underline;
    }
  }

  &.is-open {
    .lang-changer__dropdown {
      opacity: 1;
      visibility: visible;
    }
  }
}
