.session-intro {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 10px;

  &__texts {
    display: grid;
  }

  &__title {
  }

  &__text {
  }
}

.s-days-line {
  display: flex;

  button {
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    margin: 0 4px;
    padding: 0;
    border: 0;
    border-radius: 50%;
    background-color: white;
    border: 1px solid black;
    font-size: 0.8rem;

    &.active {
      background-color: #c5f1de;
      border-color: #c5f1de;
      outline: none;
      // color: #fff;
    }
  }
}

.session-time-block {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  span {
    width: 90px;
    margin-right: 8px;
    font-weight: 700;
  }

  .react-datepicker-wrapper {
    input {
      width: 75px;
      text-align: center;
      border: 1px solid rgba(29, 112, 183, 0.2);
      border-radius: 4px;
    }
  }
}

.form-group--notification {
  margin: 25px 0;
}

.form-group--single-row {
  display: flex;
  align-items: center;
  gap: 15px;
}

.form-control--select {
  max-width: 150px;
}

.session-duration--message {
  margin-bottom: 0;
}

.notification-section {
  display: grid;
  gap: 10px;
  margin-top: 10px;
}

.notification-input__group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  position: relative;

  label {
    margin-bottom: 0;
  }
}

.notification-group__switcher {
  width: 50px;
  appearance: none;
  -moz-appearance: none;

  &::before {
    content: "";
    border-radius: 1000px;
    background-color: #ccc;
    height: 22px;
    width: 50px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-11px);
    transition: all 0.15s;
  }

  &::after {
    content: "";
    position: absolute;
    background-color: white;
    top: 50%;
    left: 2px;
    transform: translate(0, -9px);
    height: 18px;
    width: 18px;
    border-radius: 50%;
    transition: all 0.15s;
  }

  &:checked {
    &::before {
      background-color: #58dac8;
    }

    &::after {
      transform: translate(28px, -9px);
    }
  }
}
