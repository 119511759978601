@import 'reboot';
@import 'local-variables';
@import 'fonts';
@import 'grid';
@import 'helpers';

hr {
  margin: 2rem 0;
  border: 1px solid #ccc;
}

.fz-18 {
  font-size: 18px;
}
