@import '../../assets/styles/local-variables';

.account-dropdown {
  position: relative;
  font-size: 1rem;

  &__hero {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      fill: $blue-primary;
    }

    span {
      color: $blue-primary;
    }
  }

  &__container {
    position: absolute;
    z-index: 5;
    top: calc(100% + 8px);
    right: 0;
    min-width: 160px;
    padding: 12px 8px;
    background-color: $white;
    box-shadow: 0 4px 8px rgba(30, 30, 30, .2);
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition: $transition-function;
  }

  &__item {
    padding: 6px 8px;
    text-transform: none;

    &:not(:last-child) {
      border-bottom: 1px solid #e0e0e0;
    }

    a {
      display: block;
      transition: $transition-function;

      &:hover {
        color: $blue-primary;
      }
    }
  }

  &.is-open {
    .account-dropdown__container {
      opacity: 1;
      visibility: visible;
    }
  }
}
