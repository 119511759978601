p {
  width: 100% !important;
}

.login-page {
  &--header {
    display: grid;
    justify-items: flex-start;
  }

  &--heading {
    margin-bottom: 0;
    font-size: 1.6rem;
  }

  &--action-text {
    color: #c1d2d8;
    font-size: 1rem;
  }

  &--form {
    display: grid;
    gap: 0.5rem;

    &__action-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }

    &__remember-me {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &__remember-label {
      font-size: 0.9rem;
      margin-bottom: 0px;
    }

    &__forgot-password {
      font-size: 0.9rem;
      color: #54bcd4 !important;
      cursor: pointer;
      transition: color 0.2s;

      &:hover {
        color: rgb(35, 117, 145) !important;
      }
    }
  }

  &--form-group__label {
    font-size: 1rem;
  }

  &__footer {
    margin-top: 0.5rem;
  }
}
