.registration {
  &--header {
    display: grid;
    justify-items: flex-start;
  }

  &--heading {
    margin-bottom: 0;
    font-size: 1.6rem;
  }

  &--action-text {
    color: #c1d2d8;
    font-size: 1rem;
  }

  &--form {
    display: grid;
    gap: 0.5rem;

    &__action-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }

    &__remember-me {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &__remember-label {
      font-size: 0.9rem;
      margin-bottom: 0px;
    }

    &__forgot-password {
      font-size: 0.9rem;
      color: #54bcd4;
      //   margin-left: 3.5rem;
    }

    &__policy-container,
    &__terms-container {
      display: grid;
      align-items: flex-start;
      margin-bottom: 0.2rem;
    }

    &__terms-container {
      margin-bottom: 1rem;
    }

    &__agree-privacy-policy,
    &__agree-terms {
      display: flex;
      align-items: center;
      gap: 7px;
    }

    &__checkbox-accept {
      cursor: pointer;
    }

    &__policy-text,
    &__terms-text {
      margin-bottom: 0;
      cursor: pointer;

      a {
        color: #54bcd4;
        text-decoration: underline;
      }
    }
  }

  &--form-password-hint {
    color: #c1d2d8;
    font-size: 14px;
    margin-top: 5px;
  }

  &--form-group__label {
    font-size: 1rem;
  }
}
