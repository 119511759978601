.reset-password {
  background-color: white;
  height: 100%;
  display: grid;
  justify-content: center;
  justify-items: center;
  padding: 4rem 1rem;

  &__main-content {
    width: 30rem;
    display: grid;
    align-content: flex-start;
    justify-items: center;
    gap: 2rem;
  }

  &__subtitle {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
  }

  &__form {
    display: grid;
    gap: 1rem;
  }
}
