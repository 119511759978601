@import "../../assets/styles/local-variables";

.tracking {
  margin-top: 4rem;
  display: grid;
  grid-template-rows: min-content 1fr;
  align-items: flex-end;

  &__in-progress {
    // background-color: red;
    position: relative;
    // margin-top: -7rem;
  }

  &__media-wrapper {
    position: relative;
    margin-bottom: 1rem;
    padding: 0;
    text-align: center;
    border-radius: 16px;

    &.with-video {
      display: flex;
      justify-content: center;
      align-items: stretch;
      width: 60vw;
      max-width: 100rem;
      height: 60vh;
      max-height: 58rem;
      border-radius: 1rem;
      border: 2px solid white;
      padding-bottom: 0;
      padding: 0;
      position: relative;

      video {
        width: 100%;
        // height: 30rem;
        background-color: #00203f;
        object-fit: cover;
        transform: rotateY(180deg);
        -webkit-transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
        // height: 40rem;
        border-radius: 1rem;
        overflow: hidden;
      }
    }
  }

  &__play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 81px;
    max-height: 72px;
    cursor: pointer;
  }

  &__progress {
    position: relative;
    height: 24px;
    margin-bottom: 1rem;
    background-color: $white;
    border-radius: 24px;
    box-shadow: 0 2px 4px rgba(80, 80, 80, 0.1);

    & > div {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      border-radius: 24px;
      background-color: $green-primary;
      transition: width 0.5s linear;
    }
  }

  &__time-title {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: -3.5rem;

    display: grid;
    gap: 3rem;
  }

  &__timer {
    height: 3rem;
  }

  &__time-left {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 0.2rem;

    &--title {
    }

    &--value {
      color: #7c8d9d;
    }
  }

  &__socket-message {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    text-align: center;
    font-size: 1.7rem;
    top: 1rem;
    padding: 0.3rem 1rem;
    border-radius: 0.5rem;
    width: auto !important;
    background-color: #00203f;
    box-shadow: 0px 0px 3px 2px rgba(255, 255, 255, 0.4);
    font-size: 1rem;
    color: white;

    p {
      margin-bottom: 0;
    }

    svg {
      height: 20px;
      width: 20px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .capture-video {
    &.hide {
      display: none;
    }

    &.show {
      display: block;
    }
  }
}

.tracking-note {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon {
    flex-shrink: 0;
    width: 38px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    margin-right: -0.1rem;
    border-radius: 50%;
    font-weight: 700;
    color: $white;
  }

  &__title {
    font-size: 0.8rem;
    color: white;
  }

  &--attention {
    .tracking-note__icon {
      background-color: tomato;
    }
  }
}

.btn--video-control {
  min-width: auto;
  padding: 0.2rem;
  border-radius: 50%;
  background-color: transparent;
}

.tracking-start__btn {
  // !important to overwrite library code
  background-color: #c5f1de !important;
  color: black !important;
  font-size: 1rem !important;
  font-weight: 300 !important;
  border-radius: 8px !important;
  margin-bottom: 1rem;

  &:hover {
    background-color: #8de3be !important;
  }
}
